/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./src/assets/vendor/bootstrap/css/bootstrap.min.css"
import "./src/assets/vendor/fontawesome-free/css/all.min.css"
import "./src/assets/vendor/animate/animate.min.css"
import "./src/assets/vendor/simple-line-icons/css/simple-line-icons.min.css"
import "./src/assets/vendor/owl.carousel/assets/owl.carousel.min.css"
import "./src/assets/vendor/owl.carousel/assets/owl.theme.default.min.css"
import "./src/assets/vendor/magnific-popup/magnific-popup.min.css"
import "./src/assets/styles/theme.css"
import "./src/assets/styles/theme-elements.css"
import "./src/assets/styles/theme-blog.css"
// import "./src/assets/styles/theme-shop.css"
import "./src/assets/styles/skins/skin-corporate-6.css"